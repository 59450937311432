.profilePage {
  margin-top: calc(32px + 64px);
  background: var(--color-background-tertiary);
  position: relative;
  z-index: 11;
}
.profilePageNoMargin {
  margin-top: 0 !important;
}
.pageHeader {
  position: sticky;
  top: 0;
  background: var(--color-background-tertiary);
  padding: 16px 20px;
  display: flex;
  align-items: center;
  z-index: 1;
}
.backButton {
  margin-right: 16px;
}

.navigateButton {
  margin-left: auto;
}

.secondaryGrid {
  margin-top: 8px;
  background: var(--color-background-primary);
  border-radius: 16px;
}
.pageItemContainer {
  padding: 18px 8px;
  height: fit-content;
  cursor: pointer;
}
.pageItemContainerDisplay {
  display: flex;
  align-items: center;
}

.logOutButtonContainer {
  margin-top: 8px;
  background: var(--color-background-primary);
  border-radius: 16px;
  padding: 16px;
}

@media (max-width: 499px) {
  .pageItemContainer:not(:nth-last-child(1 of .pageItemContainer)):after {
    width: calc(100% - 16px);
  }
}
@media (max-width: 1023px) {
  .pageItemContainer {
    position: relative;
  }
  .pageItemContainer:not(:nth-last-child(1 of .pageItemContainer)):after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 32px);
    height: 1px;
    background: var(--color-divider-divider);
  }
}

@media (min-width: 500px) {
  .profilePage {
    margin-top: 0;
    z-index: 0;
  }
  .pageHeader {
    padding: 20px 28px;
    top: calc(32px + 74px);
  }
  .pageItemContainer {
    padding: 16px;
    height: fit-content;
    cursor: pointer;
  }
}

@media (min-width: 600px) {
  .profilePage {
    margin-top: calc(32px + 98px);
  }
  .pageHeader {
    top: calc(32px + 98px);
  }
}

@media (min-width: 500px) and (max-width: 1024px) {
  .profilePage {
    margin-top: 0;
  }
}

@media (min-width: 1024px) {
  .profilePage {
    padding-top: calc(32px + 77px);
  }
  .pageItemContainer {
    background: var(--color-background-primary);
    border-radius: 40px;
    padding: 32px 30px;
    height: fit-content;
    cursor: pointer;
  }

  .pageHeader {
    position: static;
    max-width: 1172px;
    margin: 0 auto;
    padding: 40px 46px 16px;
  }

  .contentGrid {
    box-sizing: content-box;
    max-width: 1196px;
    padding: 0 34px 120px;
    margin: 24px auto 0;
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-auto-rows: auto;
    gap: 20px;
  }

  .secondaryGrid {
    margin: 0;
    background: none;
    border-radius: unset;
    height: fit-content;
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    grid-auto-rows: auto;
  }
}

@media (min-width: 1440px) {
  .secondaryGrid {
    grid-template-columns: repeat(2, 1fr);
  }

  .savedCards {
    grid-column: 1 / 3;
    grid-row: 1;
  }
  .orders {
    grid-column: 1;
    grid-row: 2;
  }
  .bonusHistory {
    grid-column: 2;
    grid-row: 2;
  }
  .savedCards[with-cards='true'] {
    grid-column: 1;
    grid-row: 1 / 3;
  }
  .orders[with-cards='true'] {
    grid-column: 2;
    grid-row: 1;
  }
  .bonusHistory[with-cards='true'] {
    grid-column: 2;
    grid-row: 2;
  }
}
