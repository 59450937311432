:root {
  --img-ic-16-chat_check: url('/src/shared/ui-kit/images/ic/16/chat_check.svg');
  --img-ic-16-chat_double_check: url('/src/shared/ui-kit/images/ic/16/chat_double_check.svg');
  --img-ic-16-check_oncolor_secondary: url('/src/shared/ui-kit/images/ic/16/check_oncolor_secondary.svg');
  --img-ic-16-copy: url('/src/shared/ui-kit/images/ic/16/copy.svg');
  --img-ic-16-gift_oncolor_secondary: url('/src/shared/ui-kit/images/ic/16/gift_oncolor_secondary.svg');

  --img-ic-18-delete: url('/src/shared/ui-kit/images/ic/18/delete.svg');

  --img-ic-24-add-picture: url('/src/shared/ui-kit/images/ic/24/add_picture.svg');
  --img-ic-24-arrow-back: url('/src/shared/ui-kit/images/ic/24/arrow_back.svg');
  --img-ic-24-bag: url('/src/shared/ui-kit/images/ic/24/bag.svg');
  --img-ic-24-bonus: url('/src/shared/ui-kit/images/ic/24/bonus.svg');
  --img-ic-24-card: url('/src/shared/ui-kit/images/ic/24/card.svg');
  --img-ic-24-cash: url('/src/shared/ui-kit/images/ic/24/cash.svg');
  --img-ic-24-catalog: url('/src/shared/ui-kit/images/ic/24/catalog.svg');
  --img-ic-24-check: url('/src/shared/ui-kit/images/ic/24/check.svg');
  --img-ic-24-checkbox-selected: url('/src/shared/ui-kit/images/ic/24/checkbox_selected.svg');
  --img-ic-24-checkbox-deselected: url('/src/shared/ui-kit/images/ic/24/checkbox_deselected.svg');
  --img-ic-24-chevron-down: url('/src/shared/ui-kit/images/ic/24/chevron_down.svg');
  --img-ic-24-chevron-right: url('/src/shared/ui-kit/images/ic/24/chevron_right.svg');
  --img-ic-24-chevron-right-on-color: url('/src/shared/ui-kit/images/ic/24/chevron_right_oncolor.svg');
  --img-ic-24-chevron-up: url('/src/shared/ui-kit/images/ic/24/chevron_up.svg');
  --img-ic-24-clear-field: url('/src/shared/ui-kit/images/ic/24/clear_field.svg');
  --img-ic-24-close-on-color: url('/src/shared/ui-kit/images/ic/24/close_oncolor.svg');
  --img-ic-24-close: url('/src/shared/ui-kit/images/ic/24/close.svg');
  --img-ic-24-close-on-color: url('/src/shared/ui-kit/images/ic/24/close_oncolor.svg');
  --img-ic-24-credit-card: url('/src/shared/ui-kit/images/ic/24/credit_card.svg');
  --img-ic-24-delete: url('/src/shared/ui-kit/images/ic/24/delete.svg');
  --img-ic-24-delete-on-color: url('/src/shared/ui-kit/images/ic/24/delete_oncolor.svg');
  --img-ic-24-edit: url('/src/shared/ui-kit/images/ic/24/edit.svg');
  --img-ic-24-googleplay: url('/src/shared/ui-kit/images/ic/24/googlepay.svg');
  --img-ic-24-help: url('/src/shared/ui-kit/images/ic/24/help.svg');
  --img-ic-24-info: url('/src/shared/ui-kit/images/ic/24/info.svg');
  --img-ic-24-marker: url('/src/shared/ui-kit/images/ic/24/marker.svg');
  --img-ic-24-menu: url('/src/shared/ui-kit/images/ic/24/menu.svg');
  --img-ic-24-minus: url('/src/shared/ui-kit/images/ic/24/minus.svg');
  --img-ic-24-minus-on-color: url('/src/shared/ui-kit/images/ic/24/minus_oncolor.svg');
  --img-ic-24-my-location: url('/src/shared/ui-kit/images/ic/24/my_location.svg');
  --img-ic-24-newcard: url('/src/shared/ui-kit/images/ic/24/newcard.svg');
  --img-ic-24-nutrition-facts: url('/src/shared/ui-kit/images/ic/24/nutrition_facts.svg');
  --img-ic-24-phone: url('/src/shared/ui-kit/images/ic/24/phone.svg');
  --img-ic-24-picture: url('/src/shared/ui-kit/images/ic/24/picture.svg');
  --img-ic-24-plus: url('/src/shared/ui-kit/images/ic/24/plus.svg');
  --img-ic-24-plus-on-color: url('/src/shared/ui-kit/images/ic/24/plus_oncolor.svg');
  --img-ic-24-profile: url('/src/shared/ui-kit/images/ic/24/profile.svg');
  --img-ic-24-restaurant: url('/src/shared/ui-kit/images/ic/24/restaurant.svg');
  --img-ic-24-tg: url('/src/shared/ui-kit/images/ic/24/tg.svg');
  --img-ic-24-time-delivery: url('/src/shared/ui-kit/images/ic/24/time_delivery.svg');
  --img-ic-24-tt: url('/src/shared/ui-kit/images/ic/24/tt.svg');
  --img-ic-24-vk: url('/src/shared/ui-kit/images/ic/24/vk.svg');

  /*------------- PLACEHOLDER -------------*/
  --img-placeholder-apple: url('/src/shared/ui-kit/images/img/placeholder/apple.svg');
  --img-placeholder-avatar: url('/src/shared/ui-kit/images/img/placeholder/avatar.svg');
  --img-placeholder-big-picture: url('/src/shared/ui-kit/images/img/placeholder/big_picture.svg');
  --img-placeholder-bonus: url('/src/shared/ui-kit/images/img/placeholder/bonus.png');
  --img-placeholder-card-picture-mobile: url('/src/shared/ui-kit/images/img/placeholder/card_picture/desktop.svg');
  --img-placeholder-card-picture-desktop: url('/src/shared/ui-kit/images/img/placeholder/card_picture/mobile.svg');
  --img-placeholder-delivery: url('/src/shared/ui-kit/images/img/placeholder/delivery.svg');
  --img-placeholder-desk-big-avatar: url('/src/shared/ui-kit/images/img/placeholder/desk_big_avatar.svg');
  --img-placeholder-desk-small-avatar: url('/src/shared/ui-kit/images/img/placeholder/desk_small_avatar.svg');
  --img-placeholder-gift: url('/src/shared/ui-kit/images/img/placeholder/gift.svg');
  --img-placeholder-google: url('/src/shared/ui-kit/images/img/placeholder/google.svg');
  --img-placeholder-huawei: url('/src/shared/ui-kit/images/img/placeholder/huawei.svg');
  --img-placeholder-internet: url('/src/shared/ui-kit/images/img/placeholder/internet.svg');
  --img-placeholder-logo: url('/src/shared/ui-kit/images/img/placeholder/logo.svg');
  --img-placeholder-search: url('/src/shared/ui-kit/images/img/placeholder/search.svg');
  --img-placeholder-small-picture: url('/src/shared/ui-kit/images/img/placeholder/small_picture.svg');

  /*-------------  MODAL -------------  */
  --img-modal-birthday: url('/src/shared/ui-kit/images/img/modal/birthday.svg');
  --img-modal-error: url('/src/shared/ui-kit/images/img/modal/error.svg');
  --img-modal-help: url('/src/shared/ui-kit/images/img/modal/help.svg');
  --img-modal-restaurant-closed: url('/src/shared/ui-kit/images/img/modal/restaurant_closed.svg');
  --img-modal-settings: url('/src/shared/ui-kit/images/img/modal/settings.svg');
  --img-modal-sorry: url('/src/shared/ui-kit/images/img/modal/sorry.svg');
  --img-modal-time: url('/src/shared/ui-kit/images/img/modal/time.svg');
  --img-modal-map: url('/src/shared/ui-kit/images/img/modal/map.svg');
  --img-modal-shopping-cart: url('/src/shared/ui-kit/images/img/modal/shopping_cart.svg');
}
