.savedCardsPage {
  margin-top: calc(32px + 64px);
  background: var(--color-background-tertiary);
  position: relative;
  z-index: 11;
}
.pageHeader {
  position: sticky;
  top: 0;
  background: var(--color-background-tertiary);
  padding: 16px 20px;
  display: flex;
  align-items: center;
  z-index: 2;
}
.backButton {
  margin-right: 16px;
}

.navigateButton {
  margin-left: auto;
}

.wrapper {
  width: 100%;
  height: fit-content;
  background: var(--color-background-primary);
  border-radius: 16px;
  padding: 16px 0;
}
.content {
  padding-bottom: 84px;
}

.slide {
  width: fit-content;
  height: fit-content;
}
.slide:nth-child(1 of .slide) {
  margin-left: 8px;
}
.slide:nth-last-child(1 of .slide) {
  margin-right: 8px;
}

@media (min-width: 500px) {
  .savedCardsPage {
    margin-top: 0;
    z-index: 0;
    /* min-height: calc(100dvh - 166px); */
  }
  .pageHeader {
    padding: 20px 28px;
    top: calc(32px + 74px);
  }
}

@media (min-width: 600px) {
  .pageHeader {
    top: calc(32px + 98px);
  }
}

@media (min-width: 1024px) {
  .savedCardsPage {
    padding-top: calc(32px + 77px);
  }
  .pageHeader {
    position: static;
    max-width: 1172px;
    margin: 0 auto;
    padding: 40px 46px 16px;
  }
  .content {
    box-sizing: border-box;
    max-width: 1196px;
    padding: 0 34px 60px;
    box-sizing: border-box;
    margin: 24px auto 0;
  }
  .wrapper {
    width: fit-content;
    border-radius: 40px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 30px;
  }
}
